import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Group } from 'src/app/shared/model/equipment.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Timezone } from '../../shared/model/timezone.model';
import { EquipmentService } from '../../shared/services/equipment.service';
import { LocalesService } from '../../shared/services/locales.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { TranslateService } from '@ngx-translate/core';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { EquipmentGroupService } from 'src/app/shared/services/equipment-group.service';
import { Router } from '@angular/router';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { BasicList } from '../equipment-list/basic-list';
import { EquipmentGroup } from '../../shared/model/equipment-group.model';

@Component({
  selector: 'app-group',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GroupDetailsComponent implements OnInit {
  equipmentTypesDropDown = [];
  isInit = true;
  userLocale = 'en_US';
  locales: Locale[];
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  parentsDropDown = [];

  /* MOCK -- [DELETE VALUE] */
  statusesDropdown = [
    {
      defaultLocale: 'en_US',
      displayLabel: 'ACTIVE',
      displayLabels: {
        enUS: 'ACTIVE',
      },
      name: 'ACTIVE',
    },
    {
      defaultLocale: 'en_US',
      displayLabel: 'INACTIVE',
      displayLabels: {
        enUS: 'INACTIVE',
      },
      name: 'INACTIVE',
    },
  ];

  equipmentsSourceList = [];
  equipmentsDestinationList: BasicList;

  postingEquipment = false;
  selectedEquipmentType = 'GROUP';
  timezones: Timezone[];
  noEquipmentSelected = true;

  descriptionPlaceholder = '';
  namePlaceholder = '';
  shortDisplayLabelPlaceholder = '';
  descriptionLabel = '';
  nameLabel = '';
  shortDisplayLabel = '';

  loadingParents = true;
  loadingEquipments = false;

  readonly APPPREFIX = 'eqp';
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  private _mode: string;
  private _group: Group;
  private _data: any;

  _equipmentgroup: any;
  disableParent: boolean = false;

  constructor(
    private timezonesService: TimezonesService,
    private translateService: TranslateService,
    private orgSelectorService: ContextSelectorService,
    private equipmentService: EquipmentService,
    private equipmentGroupService: EquipmentGroupService,
    private localesService: LocalesService,
    private ngxCookieService: NgxCookieService,
    private router: Router,
  ) {
    this.timezones = this.timezonesService.timezones;
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.equipmentsDestinationList = new BasicList('destination');

    this.equipmentService.addToFlexibleAsset$.next(false);

    this.nameLabel = this.translateService.instant('group.placeholder.inputLabel');
    this.shortDisplayLabel = this.translateService.instant('sharedFields.create.shortDisplay');
    this.descriptionPlaceholder = this.translateService.instant('group.placeholder.description');
    this.namePlaceholder = this.translateService.instant('group.placeholder.name');
    this.shortDisplayLabelPlaceholder = this.translateService.instant('group.create.placeholder.shortDisplayLabel');
    this.descriptionLabel = this.translateService.instant('group.placeholder.descriptionLabel');

    const group: Partial<Group> = {
      defaultLocale: 'en_US',
      timezone: 'America/New_York',
      displayLabels: {},
    };

    this.populateGroup(group);

    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.equipmentService.getOrgSites(org.id);
        if (!this.router.isActive('/', true) && !this.router.isActive('/create', true) && !this.isInit) {
          await this.router.navigate([`/`], {});
        }
        this.isInit = false;
      }
    });

    this.equipmentService.orgSites$.subscribe(async parentSpaces => {
      this.parentsDropDown = [];
      if (parentSpaces.length > 0) {
        let yetToBeLookedAt: any[] = [...parentSpaces];
        let potentialParents = [];
        // iterate over the entire tree to find potential parents
        while (yetToBeLookedAt.length > 0) {
          let space = yetToBeLookedAt.pop();
          if (!space) {
            continue;
          }
          // add all of the children to be iterated over
          if (space.children && space.children.length > 0) {
            yetToBeLookedAt = [...yetToBeLookedAt, ...space.children];
          }
          // skip the current group as a potential parent
          if (this.group && space.id === this.group.id) {
            continue;
          }

          if (space.spaceType?.toLowerCase() === 'site') {
            potentialParents.push(space);
            continue;
          }

          if (space.spaceType?.toLowerCase() === 'spacegroup' && space.coreSpaceType?.toLowerCase() === 'equipment') {
            potentialParents.push(space);
            continue;
          }

          potentialParents.push(space);
        }

        this.parentsDropDown = potentialParents.sort(this.sortParentList);

        this.loadingParents = false;
      }
    });

    this.equipmentService.sitesEquipments$.subscribe(async equipment => {
      if (equipment.length > 0) {
        let group: EquipmentGroup = equipment.find(equip => equip.id === this.group.id) as EquipmentGroup;
        this.equipmentsDestinationList.list = group?.children || [];
        this.equipmentsSourceList = equipment.filter(equip => equip.id !== this.group.id);
      } else {
        this.equipmentsSourceList = [];
      }
      this.loadingEquipments = false;
    });

    this.equipmentGroupService.equipmentLength$.subscribe(length => {
      if (length > 0) {
        this.noEquipmentSelected = false;
      } else {
        this.noEquipmentSelected = true;
      }
    });

    this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;

      if (this.isCreateMode) {
        this.multiLocaleConfig.supportedLocales = [locale];
        this.group.defaultLocale = this.userLocale;
      } else if (this.isEditMode) {
        this.buildSupportedLocales();
      }
    });
  }

  ngOnInit() {}

  @Input()
  set equipmentgroup(equipmentgroup: any) {
    this.group = equipmentgroup;
    this.group.equipmentType = 'EQUIPMENT_GROUP';
    this.buildSupportedLocales();
  }

  get equipmentgroup() {
    return this.group;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
    this.group.defaultLocale = appData.userLocale;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  // @Input()
  set group(group: Group) {
    this._group = group;
    this._group.displayLabels = this.group.displayLabels;
    this.buildSupportedLocales();
    if (group?.parentId && group.parentId != '') {
      this.loadingEquipments = true;
      if (this.equipmentService.orgId$.value) {
        this.handleSelectionChangeSites({ value: group.parentId });
      }
      this.equipmentService.setToloadEquipmentSites(group.parentId);
    }
  }

  get group() {
    return this._group;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  isMovedItem(data: boolean) {
    if (this.isEditMode) {
      this.disableParent = data;
    }
  }

  selectedEquipments() {
    return this.equipmentsDestinationList.list && this.equipmentsDestinationList.list.length > 0;
  }

  sortParentList(x, y) {
    return x.displayLabel?.localeCompare(y.displayLabel);
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  populateGroup(groupData) {
    this._group = groupData;
  }

  getTimezone() {
    return new Timezone();
  }

  buildSupportedLocales() {
    if (
      this.multiLocaleConfig.supportedLocales &&
      this._data.equipmentgroup &&
      this._data.equipmentgroup.displayLabels
    ) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipmentgroup.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  noEquipmentsToShow() {
    return this.equipmentsSourceList.length === 0 && this.equipmentsDestinationList.list.length === 0;
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
  }
  handleSelectionChangeSites(event) {
    this.loadingEquipments = true;
    this.equipmentService.getEquipmentSpaceFromSites(event.value).then(() => (this.loadingEquipments = false));
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this.group.equipmentType) {
      return this._data.equipmentTypes.filter((e: any) => e.name === this.group.equipmentType)[0]?.displayLabel;
    }
  }

  getStatusDisplayLabel() {
    if (this.statusesDropdown && this.group.status) {
      return this.statusesDropdown.filter((e: any) => e.name === this.group.status)[0].displayLabel;
    }
  }
}
