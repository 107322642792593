import { EquipmentGroup } from './../shared/model/equipment-group.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MeterDetailsComponent } from '../details/meter/meter-details.component';
import { EquipmentService } from '../shared/services/equipment.service';
import { SupportingDataService } from '../shared/services/supporting-data.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { TimezonesService } from '../shared/services/timezones.service';
import { LocalesService } from '../shared/services/locales.service';
import { Equipment } from '../shared/model/equipment.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../delete/confirmation-dialog/delete-confirmation-dialog.component';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { OrganizationsService } from '../shared/services/organizations.service';
import { EquipmentDetailsService } from '../shared/services/equipment-details.service';
import { EquipmentGroupService } from '../shared/services/equipment-group.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  equipmentTypes = [];
  isEquipment = true;
  isEquipmentGroup = false;
  equipmentId = '';
  equipmentOrgId: '';
  org = '';
  operators = [];
  sites = [];
  site = '';
  equipment: Equipment;
  subscriptions = [];
  loadingEquipment = false;
  loadingEquipmentList = true;
  equipmentType = 'METER';
  appData: any = {};
  multiLocaleConfig: LocaleBaseConfig = {
    locales: [new Locale()],
    supportedLocales: [new Locale()],
    defaultLocale: new Locale(),
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
    id: '',
  };
  readonly mode = 'view';

  meterDetailsComponentView: MeterDetailsComponent;

  @ViewChild(MeterDetailsComponent)
  set meterComponent(meterDetailsComponent: MeterDetailsComponent) {
    this.meterDetailsComponentView = meterDetailsComponent;
  }

  loadingEquipmentGroup = false;
  equipmentGroup: EquipmentGroup;

  constructor(
    private router: Router,
    private equipmentService: EquipmentService,
    private supportingDataService: SupportingDataService,
    private route: ActivatedRoute,
    private ngxCookieService: NgxCookieService,
    private orgSelectorService: ContextSelectorService,
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private equipmentDetailsService: EquipmentDetailsService,
    private equipmentGroupService: EquipmentGroupService,
  ) {
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        const { id } = params;
        this.equipmentId = id;
        if (this.route.queryParams['value']['type'] && this.route.queryParams['value']['type'] === 'EQUIPMENT_GROUP') {
          this.equipmentGroup = null;
          this.loadingEquipmentGroup = true;
          this.organizationsService.getOrgs();
          this.equipmentDetailsService.selectedEquipment.next(id);
          await this.equipmentGroupService.getEquipmentGroupById(id);
          this.isEquipment = false;
          this.loadingEquipmentGroup = false;
          this.isEquipmentGroup = true;
        } else {
          this.equipment = null;
          this.loadingEquipment = true;
          this.isEquipmentGroup = false;
          this.equipmentDetailsService.selectedEquipment.next(id);
          this.isEquipment = true;
          await this.equipmentDetailsService.getEquipmentDetails(id);
          this.loadingEquipment = false;
        }
      }
    });

    const currentContextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.equipmentService.setOrgEquipment(org.id);
      }
    });

    const localeSub = this.localesService.locales$.subscribe(locales => {
      this.multiLocaleConfig.locales = locales;
      const locale = this.localesService.checkForLocale(this.appData.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.multiLocaleConfig.supportedLocales = [locale];
    });

    const equipSub = this.equipmentDetailsService.equipment$.subscribe(equipment => {
      if (equipment) {
        const { equipmentType } = equipment;
        this.equipment = equipment;
        this.appData.equipment = equipment;
        this.equipmentType = equipmentType;
        if (this.equipment && this.equipment.organization) {
          this.organizationsService.getOrgs(this.equipment.organization.id);
        } else {
          // fallback in case we don't have org for whatever reason.
          this.organizationsService.getOrgs();
        }
      }
    });

    const equipmentGroupSub = this.equipmentGroupService.fullEquipmentGroup$.subscribe(equipmentGroup => {
      if (equipmentGroup) {
        this.equipmentGroup = equipmentGroup;
      }
    });

    const operatorSub = this.supportingDataService.operators$.subscribe(operators => {
      if (operators.length > 0) {
        this.operators = operators;
      }
    });

    const equipTypesSub = this.equipmentService.equipmentTypes$.subscribe(equipmentTypes => {
      if (equipmentTypes.length > 0) {
        this.equipmentTypes = equipmentTypes;
        this.appData.equipmentTypes = equipmentTypes;
      }
    });

    const siteSub = this.equipmentService.sites$.subscribe(sites => {
      this.appData.sites = sites;
    });

    const siteMetersSub = this.equipmentService.siteMeters$.subscribe(siteMeters => {
      this.appData.siteMeters = siteMeters;
    });

    const siteMachineSub = this.equipmentService.siteMachines$.subscribe(siteMachines => {
      if (siteMachines !== null) {
        this.appData.loadingSiteMachines = false;
        this.appData.siteMachines = siteMachines;
      }
    });

    const deviceSub = this.equipmentService.devices$.subscribe(devices => {
      this.appData.devices = devices;
    });

    const fullEquipmentListSub = this.equipmentService.flattenedOrgEquipment$.subscribe(equipmentList => {
      this.loadingEquipmentList = !(equipmentList && equipmentList.length);
    });

    this.subscriptions.push(
      deviceSub,
      siteMachineSub,
      siteMetersSub,
      equipTypesSub,
      equipSub,
      operatorSub,
      localeSub,
      routeSub,
      siteSub,
      equipmentGroupSub,
      currentContextSub,
      fullEquipmentListSub,
    );

    this.localesService.setLocales();
    this.equipmentService.setEquipmentTypes();
    this.supportingDataService.setOperators();
    this.timezonesService.setTimezones();
    this.equipmentService.setDevicesAndCommands();
    this.appData.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['']);
  }

  handleEdit() {
    if (this.isEquipment) {
      this.router.navigate([`details/${this.equipmentId}/edit`]);
    }

    if (this.isEquipmentGroup) {
      this.router.navigate([`details/${this.equipmentId}/edit`], { queryParams: { type: 'EQUIPMENT_GROUP' } });
    }
  }

  openDeleteConfirmation() {
    const dialogConfig = new MatDialogConfig();
    const groupLabel =
      this.equipmentGroup && this.equipmentGroup.coreSpaceType
        ? this.equipmentGroup.coreSpaceType.toLowerCase()
        : 'equipment';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: this.isEquipment ? this.equipment.id : this.equipmentGroup.id,
      displayLabel: this.isEquipment ? this.equipment.displayLabel : this.equipmentGroup.displayLabel,
      equipmentType: this.isEquipment ? this.equipment.equipmentType.toLowerCase().replace('_', '-') : groupLabel,
      isGroup: this.isEquipmentGroup,
    };
    this.dialog.open(DeleteConfirmationComponent, dialogConfig);
    // dialog.afterClosed().subscribe( () => {
    //   this.deleteButton.nativeElement.blur();
    // });
  }
}
