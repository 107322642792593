import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { FlexibleAsset } from '../model/flexible-asset.model';


@Injectable()
export class FlexibleAssetsService {
  constructor(
    private apiService: ApiService,
  ) {}

  async getFlexibleAssets(site_id: string) {
    try {
      return await this.apiService.get(`sites/${site_id}/flexible-assets`);
    } catch (err) {
      console.log('Could not load flexible assets.', err);
    }
  }

  appendEquipmentToFlexibleAsset(site: string, equipment: any, asset: FlexibleAsset) {
    const equipmentType = equipment.equipmentType;
    asset.owner_id = site;
    switch (equipmentType) {
      case 'CONTROL_SET':
        if(equipment.controlElements) {
          equipment.controlElements.forEach((controlElement: any) => {
            const id = controlElement.id;
            asset.commands.push(id);
          });
        }
        break;
      case 'METER':
        if(equipment.points) {
          equipment.points.forEach((point: any) => {
            if(point.spaceType == 'MeasuredPoint') {
              const id = point.id;
              asset.performance.push(id);
              asset.monitors.push(id);
            }
          });
        }
        break;
      default:
        asset.includes.push(equipment.id);
        break;
    }
    return asset;
  }

  addMeterFromEquipmentGroup(meter: any, asset: FlexibleAsset, siteId: string) {
    asset.owner_id = siteId;
    if(meter.points) {
      meter.points.forEach((point: any) => {
        if(point.spaceType == 'MeasuredPoint') {
          const id = point.id;
          asset.performance = asset.performance.filter(pointId => pointId !== id);
          asset.monitors = asset.monitors.filter(pointId => pointId !== id);
          asset.submeters.push(id);
        }
      });
    }
    return asset;
  }

  removeMeterFromEquipmentGroup(meter: any, asset: FlexibleAsset, siteId: string) {
    asset.owner_id = siteId;
    if(meter.points) {
      meter.points.forEach((point: any) => {
        if(point.spaceType == 'MeasuredPoint') {
          const id = point.id;
          asset.submeters = asset.submeters.filter(pointId => pointId !== id);
          asset.monitors.push(id);
          asset.performance.push(id);
        }
      });
    }
    return asset;
  }

  async updateFlexibleAsset(body: FlexibleAsset) {
    const assetId = body.id;
    const response = await this.apiService.put(`flexible-assets/${assetId}`, body);
    return convertKeys.toCamel<any>(response);
  }
}
