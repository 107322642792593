import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BaseEquipment } from '../../shared/model/equipment.model';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalesService } from '../../shared/services/locales.service';
import { EquipmentService } from '../../shared/services/equipment.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-machine',
  templateUrl: './machine-details.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MachineDetailsComponent implements OnInit {
  timezones: Timezone[];
  // multi locale config
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';
  siteIdToDisplay: string = '';
  addToFlexibleAsset = true;

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  private _mode: string;
  private _machine: BaseEquipment;
  private _data: any;

  constructor(
    private timezonesService: TimezonesService,
    private translateService: TranslateService,
    private localesService: LocalesService,
    private equipmentService: EquipmentService
  ) {
    this.timezones = this.timezonesService.timezones;

    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('machine.create.placeholder.name');
    this.descriptionLabel = this.translateService.instant('sharedFields.create.description');
    this.descriptionPlaceholder = this.descriptionLabel;
    this.equipmentService.addToFlexibleAsset$.next(this.addToFlexibleAsset);

    const machine: Partial<BaseEquipment> = {
      siteId: '-1',
      defaultLocale: 'en_US',
      timezone: 'America/New_York',
      displayLabels: {},
      descriptions: {},
    };

    this.populateMachine(machine);
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set machine(machine: BaseEquipment) {
    this._machine = machine;
    if (machine.site) {
      this._machine.siteId = this._machine.site.id;
    }
    this.buildSupportedLocales();
  }

  get machine() {
    return this._machine;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
    this.machine.defaultLocale = appData.userLocale;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  populateMachine(machineData) {
    this._machine = machineData;
  }

  getTimezoneForMachine() {
    if (this.timezones.length > 0) {
      return (
        this.timezones.find(timezone => this._machine.timezone.indexOf(timezone.timezoneName) !== -1).displayLabel ||
        new Timezone()
      );
    }

    return new Timezone();
  }

  getLocaleForMachine() {
    if (this.multiLocaleConfig.locales.length > 0) {
      return (
        this.multiLocaleConfig.locales.find(locale => this._machine.defaultLocale.indexOf(locale.localeName) !== -1)
          .displayLabel || new Locale()
      );
    }

    return new Locale();
  }

  getSiteforMachine() {
    if (this._machine) {
      return this._machine.site.displayLabel;
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this.machine.equipmentType) {
      return this._data.equipmentTypes.filter((e: any) => e.name === this.machine.equipmentType)[0].displayLabel;
    }
  }

  onSiteChange({ value: siteId }) {
    this._data.siteId = siteId;
    const selectedSite = this._data.sites.find(site => siteId === site.id);
    if (selectedSite) {
      this.localesService.useSitesDefaultLocaleAndTimezone(selectedSite, this.machine, this.multiLocaleConfig);
      this.siteIdToDisplay = selectedSite.displayLabel;
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this._data.equipment && this._data.equipment.displayLabels) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipment.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  toggleAddToFlexibleAsset(event: MatCheckboxChange) {
    this.equipmentService.addToFlexibleAsset$.next(event.checked);
  }

  ngOnInit() {}
}
