<div id="meterForm" data-automation="meterForm" [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'" ngModelGroup="meterForm">
    <div class="form-wrapper">
        <div class="form-entry equipment-type" *ngIf="isEditMode">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'equipment.create.equipment_type' | translate }}</mat-label>
                <mat-select
                    id="equipmentType"
                    name="equipmentType"
                    #equipmentType="ngModel"
                    [ngClass]="{
                        failure: equipmentType.invalid && (equipmentType.dirty || equipmentType.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="this._meter.equipmentType"
                    data-automation="equipmentTypeSelector"
                    data-url-persist
                    disabled
                    placeholder="{{ getEquipmentTypeDisplayLabel() }}"
                >
                    <mat-option
                        *ngFor="let equipType of _data.equipmentTypesDropDown"
                        [value]="equipType.name"
                        id="equipType_{{ equipType.name }}"
                        >{{ equipType.displayLabel }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-entry" *ngIf="isCreateMode || isEditMode">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'sharedFields.create.supported_locales' | translate }}</mat-label>
                <mat-select
                    id="supportedLocale"
                    name="supportedLocale"
                    #supportedLocale="ngModel"
                    [ngClass]="{
                        failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                        'create-select': true,
                        'input-entry': true
                    }"
                    [(ngModel)]="multiLocaleConfig.supportedLocales"
                    (selectionChange)="handleSelectionChange()"
                    data-automation="supportedLocale"
                    required
                    placeholder="{{ 'sharedFields.create.placeholder.supported_locales' | translate }}"
                    multiple
                >
                    <mat-option
                        [disabled]="limitLocaleSelectList(5, locale)"
                        *ngFor="let locale of multiLocaleConfig.locales"
                        [value]="locale"
                        >{{ locale.displayLabel }}</mat-option
                    >
                </mat-select>
                <mat-error *ngIf="supportedLocale.invalid">
                    <mat-error *ngIf="supportedLocale.errors.pattern">
                        {{ 'equipment.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-entry">
            <ng-container *ngIf="isCreateMode || isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.default_locale' | translate }}</mat-label>
                    <mat-select
                        id="locale"
                        name="locale"
                        #locale="ngModel"
                        [ngClass]="{
                            failure: locale.invalid && (locale.dirty || locale.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="meter.defaultLocale"
                        [disabled]="multiLocaleConfig.supportedLocales.length === 0"
                        data-automation="locale"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.default_locale' | translate }}"
                    >
                        <mat-option
                            *ngFor="let locale of multiLocaleConfig.supportedLocales"
                            [value]="locale.localeName"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="locale.invalid">
                        <mat-error *ngIf="locale.errors.pattern">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isViewMode">
                <span class="info-title">{{ 'sharedFields.create.default_locale' | translate }}:</span>
                <div class="locale-value info-value" data-automation="locale">
                    {{ getLocaleForMeter().displayLabel }}
                </div>
            </ng-container>
        </div>

        <div class="form-entry">
            <ng-container *ngIf="isCreateMode || isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.timezone' | translate }}</mat-label>
                    <mat-select
                        id="timezone"
                        name="timezone"
                        #timezone="ngModel"
                        [ngClass]="{
                            failure: timezone.invalid && (timezone.dirty || timezone.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="meter.timezone"
                        data-automation="timezone"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.timezone_selector' | translate }}"
                    >
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                            timezone.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="timezone.invalid && (timezone.dirty || timezone.touched)">
                        <mat-error *ngIf="timezone.errors.required || timezone.errors.selectValid || timezone.errors.pattern">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isViewMode">
                <span class="info-title">{{ 'sharedFields.create.timezone' | translate }}:</span>
                <div class="timezone-value info-value" data-automation="timezone">
                    {{ getTimezoneForMeter().displayLabel }}
                </div>
            </ng-container>
        </div>

        <div class="form-entry" *ngIf="isEditMode || isCreateMode">
            <multi-locale-input
                name="displayLabels"
                [inputLabel]="nameLabel"
                [locales]="multiLocaleConfig.supportedLocales"
                [(ngModel)]="meter.displayLabels"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="namePlaceholder"
                [required]="true"
                [id]="'meter_display_labels'"
                (onChange)="handleLocaleInputChange()"
            >
            </multi-locale-input>
        </div>

        <div *ngIf="isCreateMode" class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'meter.create.device_id' | translate }}</mat-label>
                <input
                    matInput
                    id="deviceId"
                    #deviceId="ngModel"
                    type="text"
                    [ngClass]="{ failure: deviceId.invalid && (deviceId.dirty || deviceId.touched) }"
                    name="deviceId"
                    placeholder="{{ 'meter.create.placeholder.device_id' | translate }}"
                    maxlength="80"
                    [(ngModel)]="deviceName"
                    (blur)="updateMeterName()"
                    data-automation="deviceId"
                    data-url-persist
                />
            </mat-form-field>
        </div>

        <div *ngIf="isCreateMode" class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'sharedFields.create.site' | translate }}</mat-label>
                <mat-select
                    id="siteSelector"
                    name="site"
                    #site="ngModel"
                    [class.loading-field]="_data.loadingSites"
                    [ngClass]="{ failure: site.invalid && (site.dirty || site.touched) }"
                    [(ngModel)]="meter.siteId"
                    (selectionChange)="onSiteChange($event)"
                    data-automation="siteSelector"
                    appSelectValid
                    required
                    placeholder="{{
                        (sitesLength
                            ? 'sharedFields.create.placeholder.site_selector'
                            : 'sharedFields.create.placeholder.no_sites'
                        ) | translate
                    }}"
                    matTooltip="{{siteIdToDisplay}}" matTooltipPosition="above"
                >
                    <mat-option *ngFor="let site of appData.sites" [value]="site.id" id="site_{{ site.id }}"
                    matTooltip="{{site.displayLabel}}" matTooltipPosition="above">{{
                        site.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="site.invalid && (site.dirty || site.touched)">
                    <mat-error *ngIf="site.errors.required || site.errors.selectValid">
                        <span>{{ 'equipment.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
                <mat-hint *ngIf="_data.loadingSites" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="_data.loadingSites" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>
        </div>

        <div *ngIf="isViewMode" class="form-entry">
            <span class="info-title">{{ 'equipment.create.equipment_type' | translate }}:</span>
            <ng-container>
                <div class="reg-name-value info-value" data-automation="type">
                    {{ getEquipmentTypeDisplayLabel() }}
                </div>
            </ng-container>
        </div>

        <div *ngIf="isViewMode" class="form-entry">
            <span class="info-title">{{ 'sharedFields.create.site' | translate }}:</span>
            <ng-container>
                <div class="reg-name-value info-entry" data-automation="site" matTooltip="{{ getSiteForMeter() }}" matTooltipPosition="above">
                    {{ getSiteForMeter() }}
                </div>
            </ng-container>
        </div>

        <div class="form-entry">
            <ng-container *ngIf="isCreateMode || isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'meter.create.market_meter_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="marketMeterId"
                        #marketMeterId="ngModel"
                        type="text"
                        [ngClass]="{ failure: marketMeterId.invalid && (marketMeterId.dirty || marketMeterId.touched) }"
                        name="marketMeterId"
                        placeholder="{{ 'meter.create.placeholder.market_meter_id' | translate }}"
                        maxlength="80"
                        [(ngModel)]="meter.marketMeterId"
                        (change)="resetOperator($event)"
                        data-automation="marketMeterId"
                        data-url-persist
                    />
                    <mat-error *ngIf="marketMeterId.invalid">
                        <mat-error *ngIf="marketMeterId.errors.required">
                            <span>{{ 'equipment.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isViewMode">
                <mat-label class="info-title">{{ 'meter.create.market_meter_id' | translate }}:</mat-label>
                <div *ngIf="getMarketMeterId()" class="reg-name-value info-entry" data-automation="marketMeterId">
                    {{ _meter.marketMeterId }}
                </div>
                <div
                    *ngIf="!getMarketMeterId()"
                    class="reg-name-value info-placeHolder"
                    data-automation="marketMeterId"
                >
                    {{ 'meter.view.no.market_meter_id' | translate }}
                </div>
            </ng-container>
        </div>

        <div class="form-entry">
            <ng-container *ngIf="isEditMode || isCreateMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'meter.create.classic_node_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="classicNodeId"
                        #classicNodeId="ngModel"
                        type="text"
                        [ngClass]="{ failure: classicNodeId.invalid && (classicNodeId.dirty || classicNodeId.touched) }"
                        name="classicNodeId"
                        placeholder="{{ 'meter.create.placeholder.classic_node_id' | translate }}"
                        maxlength="80"
                        [(ngModel)]="meter.classicNodeId"
                        data-automation="classicNodeId"
                        data-url-persist
                    />
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="isViewMode">
                <mat-label class="info-title">{{ 'meter.create.classic_node_id' | translate }}:</mat-label>
                <div *ngIf="getClassicNodeId()" class="reg-name-value info-entry" data-automation="classicNodeId">
                    {{ _meter.classicNodeId }}
                </div>
                <div
                    *ngIf="!getClassicNodeId()"
                    class="reg-name-value info-placeHolder"
                    data-automation="classicNodeId"
                >
                    {{ 'meter.view.no.classic_node_id' | translate }}
                </div>
            </ng-container>
        </div>

        <div class="form-entry">
            <ng-container *ngIf="isCreateMode || isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'meter.create.operator' | translate }}</mat-label>
                    <mat-select
                        id="operator"
                        name="operator"
                        #operator="ngModel"
                        [ngClass]="{ failure: operator.invalid }"
                        [(ngModel)]="meter.operator"
                        data-automation="operatorSelector"
                        [class.loading-field]="loadingOperators"
                        [disabled]="!meter.marketMeterId"
                        appSelectValid
                        [required]="meter.marketMeterId !== ''"
                        placeholder="{{ 'meter.create.placeholder.operator_selector' | translate }}"
                    >
                        <mat-option
                            *ngFor="let operator of operators"
                            [value]="operator.operatorName"
                            id="operator_{{ operator.id }}"
                            >{{ operator.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="operator.invalid">
                        <span *ngIf="operator.errors.required || operator.errors.selectValid">
                            {{ 'equipment.validation.required' | translate }}
                        </span>
                    </mat-error>
                    <mat-hint *ngIf="loadingOperators" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingOperators" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isViewMode">
                <span class="info-title">{{ 'meter.create.operator' | translate }}:</span>
                <div *ngIf="getMeterOperator()" class="reg-name-value info-entry" data-automation="operator">
                    {{ _meter.operator }}
                </div>
                <div *ngIf="!getMeterOperator()" class="reg-name-value info-placeHolder" data-automation="operator">
                    {{ 'meter.view.no.operator' | translate }}
                </div>
            </ng-container>
        </div>

        <div *ngIf="isEditMode" class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'sharedFields.create.site' | translate }}</mat-label>
                <mat-select
                    id="editSiteSelector"
                    name="site"
                    #site="ngModel"
                    [(ngModel)]="meter.siteId"
                    (selectionChange)="onSiteChange($event)"
                    data-automation="siteSelector"
                    appSelectValid
                    required
                    disabled
                    placeholder="{{ getSiteForMeter() }}"
                    matTooltip="{{ getSiteForMeter() }}"
                    matTooltipPosition="above"
                >
                    <mat-option *ngFor="let site of appData.sites" [value]="site.id" id="editSite_{{ site.id }}"
                    matTooltip="{{site.displayLabel}}" matTooltipPosition="above">{{
                        site.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="site.invalid">
                    <mat-error *ngIf="site.errors.selectValid">
                        <span>{{ 'equipment.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-entry" *ngIf="isCreateMode || isEditMode">
            <multi-locale-input
                name="descriptions"
                [inputLabel]="descriptionLabel"
                [locales]="multiLocaleConfig.supportedLocales"
                [(ngModel)]="meter.descriptions"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="descriptionPlaceholder"
                [id]="'meter_descriptions'"
            >
            </multi-locale-input>
        </div>
        <div class="form-entry" *ngIf="isViewMode">
            <mat-label class="info-title">{{ 'sharedFields.create.description' | translate }}:</mat-label>
            <mat-label class="info-value" data-automation="description" *ngIf="meter.description">
                {{ meter.description }}
            </mat-label>
            <mat-label class="info-value info-placeHolder" data-automation="description" *ngIf="!meter.description">
                {{ 'sharedFields.view.placeholder.description' | translate }}
            </mat-label>
        </div>

        <div *ngIf="isCreateMode" class="form-entry form-entry-column">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'measuredPoint.create.data_provider' | translate }}</mat-label>
                <mat-select
                    id="dataProvider"
                    name="dataProvider"
                    #dataProvider="ngModel"
                    class="create-select"
                    [ngClass]="{ failure: dataProvider.invalid && (dataProvider.dirty || dataProvider.touched) }"
                    [(ngModel)]="selectedDataProvider"
                    data-automation="dataProviderSelector"
                    appSelectValid
                    [class.loading-field]="loadingDataProviders"
                    required
                    (selectionChange)="handleDataProviderChange($event)"
                    [disabled]="!applyDataProviderToAllPoints"
                    placeholder="{{ 'measuredPoint.create.placeholder.data_provider' | translate }}"
                >
                    <mat-option
                        *ngFor="let provider of dataProviders"
                        [value]="provider.id"
                        id="dataProvider_{{ provider.id }}"
                        >{{ provider.displayLabel }}</mat-option
                    >
                </mat-select>
                <mat-error *ngIf="dataProvider.invalid && (dataProvider.dirty || dataProvider.touched)">
                    <mat-error *ngIf="dataProvider.errors.required || dataProvider.errors.selectValid">
                        <span data-automation="alertRequired">
                            {{ 'equipment.validation.required' | translate }}
                        </span>
                    </mat-error>
                </mat-error>
                <mat-hint *ngIf="loadingDataProviders" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingDataProviders" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>
        </div>
        <div class="form-entry" *ngIf="isCreateMode">
          <mat-checkbox
            name="addToFlexibleAsset"
            data-automation="addToFlexibleAsset"
            [(ngModel)]="applyDataProviderToAllPoints"
            (change)="toggleDataProviderSelectionMethod($event)"
          >
            {{ 'meter.create.apply_to_all_points' | translate }}
          </mat-checkbox>
        </div>
        <div class="form-entry" *ngIf="isCreateMode">
          <mat-checkbox
            name="applyToAllPoints"
            data-automation="applyToAllPoints"
            [(ngModel)]="addToFlexibleAsset"
            (change)="toggleAddToFlexibleAsset($event)"
          >
            {{ 'sharedFields.create.add_to_flexible_asset' | translate }}
            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="applyToAllPointsTooltip"
              class="tooltip-icon"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'sharedFields.create.tooltip.add_to_flexible_asset' | translate }}"
            ></mat-icon>
          </mat-checkbox>
        </div>
    </div>

    <h3 *ngIf="meter" class="points-title" data-automation="viewTitle">
        {{ 'meter.create.points' | translate }}
    </h3>

    <hr class="form-divider" />
    <ng-container *ngIf="hasPoints && appData">
        <ng-container *ngFor="let point of points; let i = index; let c = count">
            <app-measured-points
                [mode]="mode"
                [point]="point"
                [appData]="appData"
                [meterName]="meterName"
                [siteId]="meter.siteId"
                [selectedChannels]="selectedChannels"
                [pointTypes]="pointTypes"
                [selectedDataProvider]="selectedDataProvider"
                [hideVeeSettings]="hideVee"
                [reportingIntervals]="reportingIntervals"
                [index]="i"
                [count]="c"
                [sourceIds]="sourceIds"
                [multiLocaleConfig]="multiLocaleConfig"
                [meter]="meter"
                [loadingDataProviders]="loadingDataProviders"
                (addDynamicPoint)="addDynamicPoint()"
                (deleteDynamicPoint)="deleteDynamicPoint($event)"
                (updateSelectedChannels)="updateSelectedChannels()"
                (updateSourceIds)="updateSourceIds()"
            >
            </app-measured-points>
        </ng-container>
    </ng-container>
</div>
